<script setup>
import { Link } from '@inertiajs/vue3'
import { snakeCase } from '@utils';

const props = defineProps({
  href: String,
  text: String,
  size: {
    type: String,
    default: 'lg'
  },
  target: {
    type: String,
  }
})

const id  = snakeCase(props.text) + '_button'

let sizes = 'px-3 py-2'

switch (props.size) {
  case 'xs':
    sizes = 'px-2 py-1'
    break
  case 'sm':
    sizes = 'px-2 py-1'
    break
  case 'md':
    sizes = 'px-2.5 py-1.5'
    break
  case 'lg':
    sizes = 'px-3 py-2'
    break
  case 'xl':
    sizes = 'px-3.5 py-2.5'
    break
}
</script>

<template>
  <Link
    :id="id"
    v-if="props.target != '_blank'"
    :key="sizes"
    :href="href"
    :class="`inline-block cursor-pointer text-center text-${props.size}
    font-semibold text-white bg-indigo-600 rounded-md ${sizes}`" >
    {{ props.text }}
  </Link>
  <a
    :id="id"
    v-else
    :key="sizes"
    target='_blank'
    :href="href"
    :class="`inline-block cursor-pointer text-center text-${props.size}
    font-semibold text-white bg-indigo-600 rounded-md ${sizes}`" >
    {{ props.text }}
  </a>
</template>
